export default function HowItWorks() {
  const steps = [
    {
      title: "Download and Install",
      description:
        "Download the SIMPANEL app, install it on your computer, and you're ready to start.",
    },
    {
      title: "Create a Session",
      description:
        "Launch the app and create a new session with a single click. You'll receive your own unique code.",
    },
    {
      title: "Join the Session",
      description:
        "Open your browser, go to the SIMPANEL web panel, and enter your unique code to join your session.",
    },
    {
      title: "Take Control",
      description:
        "Enjoy seamless flight control directly from your browser with SIMPANEL.",
    },
  ];

  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center bg-neutral-950">
      <div className="w-full max-w-[1152px] flex flex-col gap-y-6 py-16 ">
        <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
          How it works
        </h2>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 xs:grid-cols-1 gap-4">
          {steps.map((step, index) => (
            <div
              key={index}
              className="p-4 rounded-lg border-2 border-neutral-800 bg-transparent transition-all flex flex-col gap-2 cursor-pointer hover:border-neutral-700 hover:-translate-y-1"
            >
              <div className="w-12 h-12 rounded-full bg-sky-500 flex justify-center items-center font-medium text-lg text-neutral-50">
                {index + 1}
              </div>
              <h3 className="text-lg text-neutral-50 font-semibold">
                {step.title}
              </h3>
              <p className="sm:text-sm text-xs text-neutral-200 font-normal antialiased">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
