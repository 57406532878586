import ContactForm from "./ContactForm";

export default function Contact() {
  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center">
      <div className="w-full max-w-[1152px] gap-4 flex lg:flex-row flex-col py-12">
        <div className="w-full flex flex-col gap-y-4">
          <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
            Get in Touch
          </h2>
          <p className="sm:text-base text-sm text-neutral-200 font-normal antialiased">
            Have questions or suggestions? Feel free to reach out! I'm open to
            feedback, feature requests, and bug reports. Let’s make SIMPANEL
            even better together.
          </p>
          <div className="flex gap-x-4 items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-w-6 min-h-6"
            >
              <path
                d="M15 14C15.2 13 15.7 12.3 16.5 11.5C17.5 10.6 18 9.3 18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 9 6.2 10.2 7.5 11.5C8.2 12.2 8.8 13 9 14"
                stroke="#4ADE80"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 18H15"
                stroke="#4ADE80"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 22H14"
                stroke="#4ADE80"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h4 className="sm:text-base text-sm text-neutral-200 font-medium">
              <span className="text-neutral-100 font-semibold">
                Request a Feature
              </span>
              : Share your ideas for new features.
            </h4>
          </div>
          <div className="flex gap-x-4 items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-w-6 min-h-6"
            >
              <path
                d="M8 2L9.88 3.88"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.1191 3.88L15.9991 2"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.99909 7.12938V6.12938C8.98097 5.72413 9.04513 5.31941 9.18772 4.93964C9.33031 4.55986 9.54836 4.21292 9.82872 3.91973C10.1091 3.62655 10.4459 3.39321 10.819 3.23379C11.192 3.07438 11.5934 2.99219 11.9991 2.99219C12.4048 2.99219 12.8062 3.07438 13.1792 3.23379C13.5522 3.39321 13.8891 3.62655 14.1695 3.91973C14.4498 4.21292 14.6679 4.55986 14.8105 4.93964C14.9531 5.31941 15.0172 5.72413 14.9991 6.12938V7.12938"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 20C8.7 20 6 17.3 6 14V11C6 9.93913 6.42143 8.92172 7.17157 8.17157C7.92172 7.42143 8.93913 7 10 7H14C15.0609 7 16.0783 7.42143 16.8284 8.17157C17.5786 8.92172 18 9.93913 18 11V14C18 17.3 15.3 20 12 20Z"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 20V11"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.53 9C4.6 8.8 3 7.1 3 5"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 13H2"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M3 21C3 18.9 4.7 17.1 6.8 17"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.9707 5C20.9707 7.1 19.3707 8.8 17.4707 9"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 13H18"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.1992 17C19.2992 17.1 20.9992 18.9 20.9992 21"
                stroke="#F87171"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <h4 className="sm:text-base text-sm text-neutral-200 font-medium">
              <span className="text-neutral-100 font-semibold">
                Report a Problem
              </span>
              : Report any issues or bugs.
            </h4>
          </div>
          <div className="flex gap-x-4 items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-w-6 min-h-6"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#60A5FA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.08984 9.00057C9.32495 8.33224 9.789 7.76868 10.3998 7.4097C11.0106 7.05073 11.7287 6.91951 12.427 7.03928C13.1253 7.15906 13.7587 7.52209 14.2149 8.0641C14.6712 8.6061 14.9209 9.29209 14.9198 10.0006C14.9198 12.0006 11.9198 13.0006 11.9198 13.0006"
                stroke="#60A5FA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 17H12.01"
                stroke="#60A5FA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <h4 className="sm:text-base text-sm text-neutral-200 font-medium">
              <span className="text-neutral-100 font-semibold">
                General Questions
              </span>
              : Reach out for general inquiries or feedback.
            </h4>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
}
