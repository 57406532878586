import { useState } from "react";
import useFavicon from "../../Utils/useFavicon";
import useIP from "../../Utils/useIP";
import PageTitle from "../../Utils/PageTitle";

export default function Panel() {
  useFavicon();

  const [inputCode, setInputCode] = useState("");
  const decodedIp = useIP(inputCode);

  const handleJoin = () => {
    window.location.href = `http://${decodedIp}:8055`;
  };

  return (
    <>
      <PageTitle title={"SIMPANEL | Panel"} />
      <div className="panel h-screen w-full bg-neutral-900 p-2 max-h-screen overflow-hidden">
        <div className="h-screen w-full flex justify-center items-center">
          <div className="flex flex-col gap-y-2">
            <h1 className="text-2xl text-neutral-200 font-semibold">
              Join a room
            </h1>
            <div className="flex gap-x-2">
              <input
                type="text"
                value={inputCode}
                onChange={(e) => setInputCode(e.target.value)}
                placeholder="Enter room code"
                className="border p-2 border-neutral-700 bg-neutral-800 rounded outline-none text-neutral-50"
              />
              <button
                onClick={handleJoin}
                className="bg-sky-400 text-neutral-50 py-2 px-4 rounded"
              >
                Join
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
