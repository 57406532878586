import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center">
      <div className="flex flex-col w-full max-w-[1152px] gap-y-5 mt-24 mb-18 sm:mt-36 sm:mb-24">
        <h1 className="sm:text-6xl text-4xl font-bold text-neutral-50 antialiased">
          Fly Smarter, Not Harder
        </h1>
        <p className="sm:text-base text-sm text-neutral-200 font-normal antialiased sm:max-w-[600px]">
          Take your flight simulation experience to a new level. With SIMPANEL,
          connecting to your cockpit is as easy as entering a code. In seconds,
          you’re in control—on your phone, tablet, or any device. Get instant
          updates, simple setup, and full customization. Cockpit management has
          never been easier.
        </p>
        <div className="flex gap-x-2">
          <a
            href="https://github.com/kndxiu/SIMPANEL-App/releases/latest/download/SIMPANEL.Setup.exe"
            target="_blank"
            rel="noreferrer"
          >
            <div className="font-medium text-base text-neutral-100 px-4 py-2 bg-sky-500 rounded cursor-pointer">
              Download
            </div>
          </a>

          <Link to="/panel">
            <div className="font-medium text-base text-neutral-100 px-4 py-2 bg-neutral-50/10 backdrop-blur-xl rounded cursor-pointer border border-transparent transition-all hover:border-neutral-50/25">
              Panel
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
