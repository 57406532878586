import { useRef } from "react";
import Background from "../Background";
import Contact from "../Contact";
import Features from "./Features";
import Footer from "../Footer";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import Navbar from "../Navbar";
import useFavicon from "../../Utils/useFavicon";
import PageTitle from "../../Utils/PageTitle";

export default function Home() {
  const wrapperRef = useRef(null);
  useFavicon();

  return (
    <>
      <PageTitle title={"SIMPANEL | Home"} />
      <div
        ref={wrapperRef}
        className="wrapper bg-neutral-900 relative min-h-svh max-w-svw max-h-svh overflow-x-hidden z-[0]"
      >
        <Navbar wrapperRef={wrapperRef} activePage="Home" />
        <Hero />
        <Features />
        <HowItWorks />
        <Contact />
        <Footer wrapperRef={wrapperRef} />
        <Background />
      </div>
    </>
  );
}
