import { useRef } from "react";
import Contact from "../Contact";
import Footer from "../Footer";
import Navbar from "../Navbar";
import useFavicon from "../../Utils/useFavicon";
import SubBackground from "../SubBackground";
import PageTitle from "../../Utils/PageTitle";

export default function About() {
  const wrapperRef = useRef(null);
  useFavicon();

  return (
    <>
      <PageTitle title={"SIMPANEL | About"} />
      <div
        ref={wrapperRef}
        className="wrapper bg-neutral-900 relative min-h-svh max-w-svw max-h-svh overflow-x-hidden z-[0]"
      >
        <Navbar wrapperRef={wrapperRef} activePage="About" />
        <SubBackground index={2} text={"About"} />
        <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center">
          <div className="w-full max-w-[1152px] gap-4 flex flex-col py-12">
            <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
              Introduction
            </h2>
            <p className="sm:text-base text-sm text-neutral-200 font-normal antialiased">
              SIMPANEL is a powerful tool designed for flight sim enthusiasts
              who crave more control and convenience. By allowing you to manage
              your cockpit directly from any device, SIMPANEL elevates your
              simming experience to a new level, combining flexibility with ease
              of use.
            </p>
          </div>
        </div>
        <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center bg-neutral-950">
          <div className="w-full max-w-[1152px] gap-4 grid sm:grid-cols-2 grid-cols-1 py-12">
            <div className="flex flex-col gap-4">
              <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
                The Story
              </h2>
              <p className="sm:text-base text-sm text-neutral-200 font-normal antialiased">
                SIMPANEL was born out of my desire for greater control and
                flexibility in flight sim. As a passionate flight simmer, I
                often found myself frustrated by the constant need to switch
                between views and interfaces. SIMPANEL was created to solve this
                problem, enabling users to manage their cockpit effortlessly
                from any nearby device. My goal was to make flying experience
                smoother, more intuitive, and ultimately, more enjoyable.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
                Mission
              </h2>
              <p className="sm:text-base text-sm text-neutral-200 font-normal antialiased">
                My mission is to make flight simming more accessible, immersive,
                and enjoyable by providing a seamless way to control your
                cockpit from any device. With SIMPANEL, your cockpit controls
                are always within reach, giving you more time to focus on your
                stunning scenery.
              </p>
            </div>
          </div>
        </div>
        <Contact />
        <Footer wrapperRef={wrapperRef} />
      </div>
    </>
  );
}
