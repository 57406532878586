import { useState } from "react";
import { Link } from "react-router-dom";

export default function Navbar({ wrapperRef, activePage }) {
  const handleScrollToTop = () => {
    wrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [isOpen, setIsOpen] = useState(false);

  const links = ["Features", "About", "Changelog", "Contact"];

  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center bg-neutral-900 border-b bg-neutral-900 border-b border-neutral-50/10 sticky top-0 z-50">
      <div className="w-full max-w-[1152px] py-3 flex-col">
        <div className="w-full flex justify-between items-center">
          <h2 className="font-bold text-2xl text-neutral-50">SIMPANEL</h2>
          <ul className="hidden lg:flex gap-x-6 items-center">
            <li
              className="font-normal text-base text-neutral-100 cursor-pointer hover:text-sky-500 transition-all relative"
              onClick={handleScrollToTop}
            >
              <Link to={"/"}>
                Home
                {activePage === "Home" && (
                  <div className="absolute left-0 bottom-0 -translate-y-1/2 w-1/2 h-[3px] rounded-r-full bg-sky-500"></div>
                )}
              </Link>
            </li>
            {links.map((link, key) => (
              <li
                key={key}
                className="font-normal text-base text-neutral-100 cursor-pointer hover:text-sky-500 transition-all relative"
              >
                <Link to={`/${link.toLocaleLowerCase()}`}>{link}</Link>
                {activePage === link && (
                  <div className="absolute left-0 bottom-0 -translate-y-1/2 w-1/2 h-[3px] rounded-r-full bg-sky-500"></div>
                )}
              </li>
            ))}
          </ul>

          <Link className="hidden lg:flex" to={"/panel"}>
            <div className="font-medium text-base text-neutral-100 px-4 py-2 bg-sky-500 rounded cursor-pointer">
              Launch Panel
            </div>
          </Link>
          <div
            className="lg:hidden flex text-neutral-200 cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="lucide lucide-menu"
            >
              <line x1="4" x2="20" y1="12" y2="12" />
              <line x1="4" x2="20" y1="6" y2="6" />
              <line x1="4" x2="20" y1="18" y2="18" />
            </svg>
          </div>
        </div>
        {isOpen && (
          <div className="flex py-2 lg:hidden">
            <ul className="w-full flex flex-col gap-y-2 text-center items-center">
              <li
                className="font-normal text-lg text-neutral-100 cursor-pointer hover:text-sky-500 transition-all relative"
                onClick={handleScrollToTop}
              >
                <Link to={"/"}>Home</Link>
              </li>
              {links.map((link, key) => (
                <li
                  key={key}
                  className="font-normal text-lg text-neutral-100 cursor-pointer hover:text-sky-500 transition-all relative"
                >
                  <Link to={`/${link.toLocaleLowerCase()}`}>{link}</Link>
                </li>
              ))}

              <Link to={"/panel"}>
                <div className="font-medium text-lg text-neutral-100 px-4 py-2 bg-sky-500 rounded cursor-pointer w-fit">
                  Launch Panel
                </div>
              </Link>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
