import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";

export default function ChangelogList() {
  const [releases, setReleases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReleases = async () => {
      try {
        const response = await fetch(
          "https://api.github.com/repos/kndxiu/SIMPANEL-App/releases"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setReleases(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchReleases();
  }, []);

  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center">
      <div className="w-full max-w-[1152px] gap-4 grid lg:grid-cols-2 grid-cols-1 py-12">
        {error && <span className="text-red-500">Error: {error}</span>}
        {loading && <span className="text-neutral-200">Loading...</span>}
        {releases.map((release) => (
          <div
            key={release.id}
            className="rounded flex flex-col border border-neutral-800"
          >
            <div className="rounded-t bg-neutral-800 p-4 flex items-center justify-between">
              <div className="flex gap-2 items-center">
                <span className="sm:text-lg text-md text-neutral-50 font-semibold">
                  {release.name}
                </span>
                {release.published_at === releases[0]?.published_at && (
                  <span className="text-xs text-green-50 bg-green-500 rounded-full py-1 px-2">
                    Latest
                  </span>
                )}
              </div>
              <span className="text-neutral-200 font-bold sm:text-base text-sm">
                {new Date(release.published_at).toLocaleDateString()}
              </span>
            </div>
            <div className="p-4 markdown">
              <ReactMarkdown>{release.body}</ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
