import { useRef } from "react";
import Contact from "../Contact";
import Footer from "../Footer";
import Navbar from "../Navbar";
import useFavicon from "../../Utils/useFavicon";
import SubBackground from "../SubBackground";
import ChangelogList from "./ChangelogList";
import { Link } from "react-router-dom";
import PageTitle from "../../Utils/PageTitle";

export default function Changelog() {
  const wrapperRef = useRef(null);
  useFavicon();

  return (
    <>
      <PageTitle title={"SIMPANEL | Changelog"} />
      <div
        ref={wrapperRef}
        className="wrapper bg-neutral-900 relative min-h-svh max-w-svw max-h-svh overflow-x-hidden z-[0]"
      >
        <Navbar wrapperRef={wrapperRef} activePage="Changelog" />
        <SubBackground index={3} text={"Changelog"} />
        <ChangelogList />
        <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center bg-neutral-950">
          <div className="w-full max-w-[1152px] gap-4 grid sm:grid-cols-2 grid-cols-1 py-12">
            <div className="flex flex-col gap-4">
              <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
                How to Update
              </h2>
              <p className="sm:text-base text-sm text-neutral-200 font-normal antialiased">
                Updating SIMPANEL is simple. Just download the latest version on
                the homepage and install it. The app will automatically update
                without needing to uninstall the previous version. Be sure to
                check this page regularly for new updates and improvements.
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
                Feedback on Updates
              </h2>
              <p className="sm:text-base text-sm text-neutral-200 font-normal antialiased">
                Your experience matters! If you have feedback on any of the
                updates or encounter issues after an update, don’t hesitate to
                reach out. Together, we can continue improving SIMPANEL.
                <br />
                <br />
                <ul className="list-disc pl-4">
                  <li>
                    <Link className="text-sky-500" to={"/contact"}>
                      Contact here
                    </Link>{" "}
                    or use the form at the bottom of the page
                  </li>
                  <li>
                    Join the discussion on{" "}
                    <a
                      className="text-sky-500"
                      href="https://forums.flightsimulator.com/t/release-simpanel-your-feedback-and-questions-welcome/653801"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MSFS Forum
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <Contact />
        <Footer wrapperRef={wrapperRef} />
      </div>
    </>
  );
}
