export default function SubBackground({ index, text }) {
  return (
    <div
      className="flex justify-center items-center w-full lg:py-16 py-8 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('backgrounds/b${index}.jpg')`,
      }}
    >
      <h1 className="sm:text-5xl text-4xl font-bold text-neutral-50 antialiased">
        {text}
      </h1>
    </div>
  );
}
