import { useState, useRef } from "react";
import useImageUpload from "../Utils/useImageUpload";
import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const form = useRef();

  const { fileData, openFileExplorer, uploadUrl, loading, error, clearImage } =
    useImageUpload();

  const [subject, setSubject] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [status, setStatus] = useState("");
  const options = [
    "Feature Request",
    "Bug Report",
    "General Inquiry",
    "Feedback",
    "Technical Support",
  ];

  const [isToggled, setIsToggled] = useState(false);

  const toggleMenu = () => {
    setIsToggled(!isToggled);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setStatus("Sending...");
    setIsSending(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        () => {
          setStatus("Success!");
          setIsSending(false);
          setTimeout(() => {
            setStatus("");
          }, 2500);
          form.current.reset();
          setSubject("");
          clearImage();
        },
        (error) => {
          setStatus(`Error: ${error}`);
          setIsSending(false);
        }
      );
  };

  return (
    <form
      className="w-full max-w-[450px] bg-neutral-800 rounded p-4 shadow-md flex flex-col gap-y-4 self-center"
      ref={form}
      onSubmit={sendEmail}
    >
      <h3 className="text-3xl font-semibold text-neutral-100">Contact</h3>
      <div className="flex sm:flex-row flex-col gap-4">
        <div className="flex flex-col gap-y-1">
          <label
            htmlFor="user_name"
            className="text-base font-medium text-neutral-200"
          >
            Name
          </label>
          <input
            required
            type="text"
            id="name"
            name="user_name"
            className="bg-neutral-900 rounded p-2 text-neutral-100 outline-none w-full"
            placeholder="Enter your name"
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <label
            htmlFor="user_email"
            className="text-base font-medium text-neutral-200"
          >
            Email
          </label>
          <input
            required
            type="email"
            id="user_email"
            name="user_email"
            className="bg-neutral-900 rounded p-2 text-neutral-100 outline-none w-full"
            placeholder="Enter your email"
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-1 relative">
        <label
          htmlFor="user_subject"
          className="text-base font-medium text-neutral-200"
        >
          Subject
          <div className="absolute bottom-2.5 right-2 text-neutral-200">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-chevron-down cursor-pointer"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
          </div>
        </label>
        <input
          required
          type="text"
          id="subject"
          name="user_subject"
          className="bg-neutral-900 rounded p-2 text-neutral-100 outline-none w-full cursor-pointer"
          placeholder="Select a subject"
          value={subject}
          readOnly
          onClick={toggleMenu}
        />
        <div
          className={`transition-all ${
            isToggled
              ? "visible opacity-100 translate-y-0"
              : "invisible opacity-0 -translate-y-2"
          } absolute top-20 left-0 w-full flex flex-col bg-neutral-900 rounded overflow-hidden border border-neutral-50/10 z-10`}
        >
          {options.map((option, key) => (
            <div
              key={key}
              className="p-2 bg-neutral-900 hover:bg-neutral-800 text-neutral-200 cursor-pointer"
              onClick={() => {
                setSubject(option);
                setIsToggled(false);
              }}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-y-1">
        <label
          htmlFor="message"
          className="text-base font-medium text-neutral-200"
        >
          Message
        </label>
        <textarea
          required
          id="email"
          name="message"
          className="bg-neutral-900 rounded p-2 text-neutral-100 outline-none w-full"
          placeholder="Type your message here"
        ></textarea>
      </div>
      <div className="flex flex-col gap-y-1">
        <label
          htmlFor="user_attachment"
          className="text-base font-medium text-neutral-200"
        >
          Attachment (optional)
        </label>
        <input
          type="text"
          id="attachment"
          name="user_attachment"
          className={`bg-neutral-900 rounded p-2 text-neutral-100 outline-none w-full cursor-pointer  ${
            uploadUrl ? "hidden" : "block"
          }`}
          placeholder="Select an image"
          readOnly
          onClick={openFileExplorer}
          value={uploadUrl || ""}
        />

        <div
          className={`bg-neutral-900 rounded p-2 text-neutral-100 outline-none w-full  ${
            uploadUrl ? "block" : "hidden"
          }`}
        >
          <div className="relative w-fit">
            <img
              src={uploadUrl}
              alt="Uploaded"
              className="rounded max-h-24 w-fit"
            />
            <div
              className="absolute top-1 right-1 text-red-300 bg-neutral-900 rounded cursor-pointer"
              onClick={clearImage}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-x"
              >
                <path d="M18 6L6 18" />
                <path d="m6 6 12 12" />
              </svg>
            </div>
          </div>
        </div>

        {loading && <p className="text-neutral-400">Uploading...</p>}
        {error && <p className="text-red-500">Error: {error}</p>}
      </div>
      <button
        disabled={isSending}
        type="submit"
        className="font-medium text-base text-neutral-100 px-4 py-2 bg-sky-500 rounded cursor-pointer w-fit"
      >
        Send
      </button>
      {status && <p className="text-neutral-400">{status}</p>}
    </form>
  );
}
