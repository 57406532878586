import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Features from "./Components/Features/Features";
import Panel from "./Components/Panel/Panel";
import About from "./Components/About/About";
import Support from "./Components/Contact/Contact";
import Changelog from "./Components/Changelog/Changelog";

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/panel" element={<Panel />} />
        <Route path="/features" element={<Features />} />
        <Route path="/about" element={<About />} />
        <Route path="/changelog" element={<Changelog />} />
        <Route path="/contact" element={<Support />} />
      </Routes>
    </Router>
  );
}
