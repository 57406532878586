import { useRef } from "react";
import Contact from "../Contact";
import Footer from "../Footer";
import Navbar from "../Navbar";
import useFavicon from "../../Utils/useFavicon";
import SubBackground from "../SubBackground";
import PageTitle from "../../Utils/PageTitle";

export default function Support() {
  const wrapperRef = useRef(null);
  useFavicon();

  return (
    <>
      <PageTitle title={"SIMPANEL | Contact"} />
      <div
        ref={wrapperRef}
        className="wrapper bg-neutral-900 relative min-h-svh max-w-svw max-h-svh overflow-x-hidden z-[0]"
      >
        <Navbar wrapperRef={wrapperRef} activePage="Contact" />
        <SubBackground index={4} text={"Contact"} />
        <Contact />
        <Footer wrapperRef={wrapperRef} />
      </div>
    </>
  );
}
