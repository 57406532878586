import { useState } from "react";

export default function HowItWorks() {
  const [openSteps, setOpenSteps] = useState({ 0: true });

  const steps = [
    {
      title: "Download and Install",
      description:
        "First, download and install the SIMPANEL app on your PC. The installation process is quick and easy, so you’ll be ready to go in just a few minutes. Make sure you have the latest version by visiting our <a class='text-sky-500' href='/'>Download Page</a>.",
    },
    {
      title: "Create a Session",
      description:
        "Next, open the SIMPANEL app and create a new session. The app sets everything up for you and manages all communications between your device and the cockpit.",
    },
    {
      title: "Connect Your Device",
      description:
        "Make sure your device is connected to the same Wi-Fi network as your PC running SIMPANEL. This is essential for seamless communication between your devices.",
    },
    {
      title: "Join the Session",
      description:
        "Now, on your chosen device, open the SIMPANEL website and click Launch Panel. Enter your session code and join the session. You’re almost there!",
    },
    {
      title: "Explore and Test",
      description:
        "Before taking full control, spend a moment exploring the user interface and testing the basic controls. The intuitive design helps you quickly find all the features you need. Make sure everything is functioning smoothly before you start your flight.",
    },
    {
      title: "Take Control",
      description:
        "With everything set up and tested, you’re ready to take control! Manage your cockpit seamlessly from your device, enjoying the smooth and reliable experience that SIMPANEL offers. For advanced users, visit SIMPANEL’s GitHub Repository to create your own custom configurations. Find the config file <a class='text-sky-500' href='https://github.com/kndxiu/SIMPANEL-App/blob/main/src/config.json' target='_blank'>here</Link>.",
    },
  ];

  const toggleStep = (index) => {
    setOpenSteps((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center bg-neutral-950">
      <div className="w-full max-w-[1152px] flex py-12 flex flex-col gap-4">
        <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
          How It Works
        </h2>
        <div className="flex flex-col">
          {steps.map((step, key) => (
            <div
              key={key}
              className="flex flex-col last:border-b last:border-neutral-600"
            >
              <div
                className="border-neutral-600 border-t border-b-0 p-4 flex justify-between text-lg font-semibold text-neutral-50 items-center cursor-pointer"
                onClick={() => toggleStep(key)}
              >
                <div className="flex gap-2">
                  <span className="text-sky-500">{key + 1}.</span>
                  <span>{step.title}</span>
                </div>
                <div className="relative w-6 h-6">
                  <div
                    className="h-[3px] w-4 rounded bg-neutral-200 absolute top-1/2 left-1/2"
                    style={{
                      transform: `translate(-50%, -50%) rotate(${
                        openSteps[key] ? "0" : "90deg"
                      })`,
                    }}
                  ></div>
                  <div className="h-[3px] w-4 rounded bg-neutral-200 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
                </div>
              </div>
              <p
                className="p-4 pt-0 text-neutral-200"
                style={{
                  display: openSteps[key] ? "unset" : "none",
                }}
                dangerouslySetInnerHTML={{ __html: step.description }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
