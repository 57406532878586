import { useRef } from "react";
import Contact from "../Contact";
import Footer from "../Footer";
import Navbar from "../Navbar";
import useFavicon from "../../Utils/useFavicon";
import FearureList from "./FearureList";
import SubBackground from "../SubBackground";
import HowItWorks from "./HowItWorks";
import PageTitle from "../../Utils/PageTitle";

export default function Features() {
  const wrapperRef = useRef(null);
  useFavicon();

  return (
    <>
      <PageTitle title={"SIMPANEL | Features"} />
      <div
        ref={wrapperRef}
        className="wrapper bg-neutral-900 relative min-h-svh max-w-svw max-h-svh overflow-x-hidden z-[0]"
      >
        <Navbar wrapperRef={wrapperRef} activePage="Features" />
        <SubBackground index={1} text={"Features"} />
        <FearureList />
        <HowItWorks />
        <Contact />
        <Footer wrapperRef={wrapperRef} />
      </div>
    </>
  );
}
