import { Link } from "react-router-dom";

export default function Footer({ wrapperRef }) {
  const handleScrollToTop = () => {
    wrapperRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const projectLinks = ["Panel", "Changelog"];
  const usefulLinks = ["Features", "About", "Changelog", "Contact"];

  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center bg-neutral-950">
      <div className="w-full max-w-[1152px] py-12 flex justify-between gap-4 sm:text-start flex-col sm:flex-row sm:items-start items-center sm:text-start text-center">
        <div className="flex flex-col gap-y-4 sm:items-start items-center sm:text-start text-center">
          <h2 className="font-bold text-2xl text-neutral-200">SIMPANEL</h2>
          <p className="sm:text-base text-sm text-neutral-500 font-normal antialiased max-w-[300px]">
            Enhance your flight sim experience with SIMPANEL. Connect instantly,
            customize fully, and control your cockpit with ease from any device.
          </p>
          <a
            href="https://github.com/kndxiu/SIMPANEL-App/releases/latest/download/SIMPANEL.Setup.exe"
            target="_blank"
            rel="noreferrer"
          >
            <div className="font-medium text-base text-neutral-100 px-4 py-2 bg-sky-500 rounded cursor-pointer w-fit">
              Download App
            </div>
          </a>
        </div>
        <div className="flex flex-col gap-y-4">
          <h3 className="text-xl text-neutral-200 font-medium">Project</h3>
          <ul className="flex flex-col gap-y-2 text-base text-neutral-500 font-normal">
            <li onClick={handleScrollToTop}>
              <Link className="hover:underline" to={"/"}>
                Home
              </Link>
            </li>
            {projectLinks.map((link, key) => (
              <li key={key}>
                <Link className="hover:underline" to={`/${link.toLowerCase()}`}>
                  {link}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col gap-y-4">
          <h3 className="text-xl text-neutral-200 font-medium">Useful Links</h3>
          <ul className="flex flex-col gap-y-2 text-base text-neutral-500 font-normal">
            <li onClick={handleScrollToTop}>
              <Link className="hover:underline" to={"/"}>
                Home
              </Link>
            </li>
            {usefulLinks.map((link, key) => (
              <li key={key} className="hover:underline">
                <Link className="hover:underline" to={`/${link.toLowerCase()}`}>
                  {link}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex flex-col gap-y-4">
          <h3 className="text-xl text-neutral-200 font-medium">Contact Info</h3>
          <ul className="flex flex-col gap-y-2 text-base text-neutral-500 font-normal sm:items-start items-center sm:text-start text-center">
            <li className="flex gap-1 items-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.334 2.66797H2.66732C1.93094 2.66797 1.33398 3.26492 1.33398 4.0013V12.0013C1.33398 12.7377 1.93094 13.3346 2.66732 13.3346H13.334C14.0704 13.3346 14.6673 12.7377 14.6673 12.0013V4.0013C14.6673 3.26492 14.0704 2.66797 13.334 2.66797Z"
                  stroke="#0EA5E9"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.6673 4.66797L8.68732 8.46797C8.4815 8.59692 8.24353 8.66531 8.00065 8.66531C7.75777 8.66531 7.5198 8.59692 7.31398 8.46797L1.33398 4.66797"
                  stroke="#0EA5E9"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <a className="hover:underline" href="mailto:kontakt@kndxiu.xyz">
                kontakt@kndxiu.xyz
              </a>
            </li>
            <li className="flex gap-1 items-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5535 2.81854C12.5178 2.33396 11.4104 1.98178 10.2526 1.78125C10.1104 2.03832 9.94429 2.38409 9.82976 2.65915C8.599 2.47406 7.37956 2.47406 6.17144 2.65915C6.05693 2.38409 5.88704 2.03832 5.74357 1.78125C4.58454 1.98178 3.47584 2.33525 2.44013 2.8211C0.351095 5.97791 -0.215207 9.0563 0.0679444 12.091C1.4535 13.1257 2.79627 13.7542 4.11638 14.1655C4.44233 13.7169 4.73302 13.2401 4.98345 12.7375C4.5065 12.5563 4.04969 12.3326 3.61805 12.073C3.73256 11.9882 3.84457 11.8995 3.95279 11.8082C6.58546 13.0396 9.44593 13.0396 12.0472 11.8082C12.1566 11.8995 12.2686 11.9882 12.3819 12.073C11.949 12.3339 11.4909 12.5576 11.014 12.7388C11.2644 13.2401 11.5538 13.7182 11.881 14.1668C13.2024 13.7555 14.5464 13.127 15.932 12.091C16.2642 8.57301 15.3644 5.52289 13.5535 2.81854ZM5.34212 10.2247C4.55181 10.2247 3.9037 9.48688 3.9037 8.58843C3.9037 7.68998 4.53797 6.95091 5.34212 6.95091C6.14628 6.95091 6.79437 7.68868 6.78053 8.58843C6.78178 9.48688 6.14628 10.2247 5.34212 10.2247ZM10.6578 10.2247C9.86752 10.2247 9.21941 9.48688 9.21941 8.58843C9.21941 7.68998 9.85366 6.95091 10.6578 6.95091C11.462 6.95091 12.1101 7.68868 12.0962 8.58843C12.0962 9.48688 11.462 10.2247 10.6578 10.2247Z"
                  fill="#0EA5E9"
                />
              </svg>
              kndxiu
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
