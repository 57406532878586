export default function FearureList() {
  const features = [
    {
      icon: "lightning",
      title: "Lightning-Fast Setup",
      description:
        "Get started in just a few taps with our incredibly quick setup process. You’ll be connected and ready to go almost instantly, making the whole experience smooth and hassle-free.",
    },
    {
      icon: "sync",
      title: "Real-Time Updates",
      description:
        "See your changes in real time across all your devices. Whether you tweak settings or adjust controls, everything updates instantly so you can maintain perfect synchronization at all times.",
    },
    {
      icon: "device",
      title: "Flexible Access",
      description:
        "Control your cockpit from any device you prefer—whether it’s your phone, tablet, or even a smartwatch. This flexibility ensures you can manage your aircraft from wherever you are within your network.",
    },
    {
      icon: "gear",
      title: "Customizable Interface",
      description:
        "Personalize your control panel exactly how you want it. Adjust layouts and settings to fit your unique flying style and preferences, giving you full control over your cockpit experience.",
    },
    {
      icon: "rabbit",
      title: "Ultra-Fast Response",
      description:
        "Experience incredibly quick feedback with ultra-fast response times. Your commands are executed almost instantly, ensuring that your cockpit controls are always accurate and timely.",
    },
    {
      icon: "puzzle",
      title: "User-Friendly Interface",
      description:
        "Enjoy a smooth, intuitive experience with a clean and user-friendly design. Navigate through your cockpit controls effortlessly, making every adjustment and setting change straightforward and enjoyable.",
    },
  ];
  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center">
      <div className="w-full max-w-[1152px] flex grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 py-12 gap-4">
        {features.map((feature, key) => (
          <div
            key={key}
            className="w-full p-4 rounded transition hover:bg-neutral-800 hover:shadow-md flex flex-col gap-4 cursor-pointer"
          >
            <img
              src={`/icons/${feature.icon}.svg`}
              alt={feature.icon}
              className="w-12 h-12"
            />

            <h3 className="text-lg text-neutral-50 font-semibold">
              {feature.title}
            </h3>
            <p className="sm:text-sm text-xs text-neutral-200 font-normal antialiased">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
