import { useState, useEffect } from "react";

const useIP = (encodedIp) => {
  const [decodedIp, setDecodedIp] = useState("");

  useEffect(() => {
    const decode = (shortCode) => {
      const isNegative = shortCode.startsWith("-");
      const base36Code = isNegative ? shortCode.slice(1) : shortCode;

      const number = parseInt(base36Code, 36);

      const finalNumber = isNegative ? -number : number;

      return [
        (finalNumber >>> 24) & 0xff,
        (finalNumber >>> 16) & 0xff,
        (finalNumber >>> 8) & 0xff,
        finalNumber & 0xff,
      ].join(".");
    };

    const decoded = decode(encodedIp);
    setDecodedIp(decoded);
  }, [encodedIp]);

  return decodedIp;
};

export default useIP;
