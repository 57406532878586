export default function Features() {
  const features = [
    {
      icon: "lightning",
      title: "Lightning-Fast Setup",
      description: "Get flying in seconds with one-click setup.",
    },
    {
      icon: "sync",
      title: "Real-Time Updates",
      description: "Stay in sync with instant, real-time updates.",
    },
    {
      icon: "device",
      title: "Flexible Access",
      description: "Control your flights easily from any nearby device.",
    },
    {
      icon: "gear",
      title: "Customizable Interface",
      description: "Personalize your cockpit with custom layouts and settings.",
    },
  ];

  return (
    <div className="2xl:px-96 xl:px-64 xl:px-48 lg:px-36 md:px-24 sm:px-8 px-4 flex justify-center items-center">
      <div className="w-full max-w-[1152px] flex flex-col gap-y-6 py-12">
        <h2 className="sm:text-4xl text-3xl text-neutral-50 font-bold">
          Features
        </h2>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
          {features.map((feature, key) => (
            <div
              key={key}
              className="p-4 rounded bg-neutral-800 transition-all flex gap-4 items-center shadow-md cursor-pointer hover:bg-neutral-700 hover:-translate-y-1"
            >
              <img
                src={`/icons/${feature.icon}.svg`}
                alt={feature.icon}
                className="w-12 h-12"
              />

              <div className="flex flex-col">
                <h3 className="text-lg text-neutral-50 font-semibold">
                  {feature.title}
                </h3>
                <p className="sm:text-sm text-xs text-neutral-200 font-normal antialiased">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
