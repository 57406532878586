export default function Background() {
  return (
    <div className="absolute top-0 w-screen h-[75svh] max-h-[720px] z-[-1]">
      <div
        className="absolute w-full h-[75svh] max-h-[720px] bg-cover bg-no-repeat bg-center"
        style={{ backgroundImage: `url('backgrounds/b0.jpg')` }}
      ></div>
      <div className="absolute w-full h-[75svh] max-h-[720px] bg-gradient-to-t from-[rgba(23,23,23,1)] to-[rgba(23,23,23,0)]"></div>
    </div>
  );
}
